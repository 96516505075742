<!--
 * @Author: your name
 * @Date: 2020-08-11 14:29:53
 * @LastEditTime: 2020-11-09 23:39:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jujubetwin\src\components\columnItem.vue
-->
<template>
	<el-table-column
		:label="item.label"
		:prop="item.prop"
		:show-overflow-tooltip="true"
		:formatter="item.formatter"
		:width="item.width"
		:sortable="item.sortable"
		align="center"
	>
		<template v-if="item.children && item.children.length > 0">
			<columnItem v-for="(subItem, index) in item.children" :key="index" :item="subItem">
				<template v-for="prop in getChildrenProps(subItem)" #[prop]="{ scope }">
					<slot :name="item.prop" :scope="scope" />
				</template>
			</columnItem>
		</template>
		<template v-if="(!item.children || item.children.length < 1) && $scopedSlots[item.prop]" #default="scope">
			<slot :name="item.prop" :scope="scope" />
		</template>
	</el-table-column>
</template>

<script>
export default {
	name: 'columnItem',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		getChildrenProps(item) {
			let props = [];
			const func = (item) => {
				if (item.children && item.children.length > 0) {
					item.children.forEach((res) => {
						if (res.children && res.children.length > 0) {
							this.getChildrenProps(res.children);
						} else {
							if (res.prop && this.$scopedSlots[res.prop]) {
								props.push(res.prop);
							}
						}
					});
				} else {
					if (item.prop && this.$scopedSlots[item.prop]) {
						props.push(item.prop);
					}
				}
			};
			func(item);
			console.log(props, 'props');
			return props;
		},
	},
};
</script>
